@import './../../../../assets/css/variables.scss';
@import './../../../../assets/css/main.scss';

.SidebarWrapper {
    z-index: 1000;
    position: $positionFixed;
    left: 242px;
    width: 0;
    height: $height100per;
    margin-left: -242px;
    overflow-y: auto;
    background: $Colorwhite;
    padding: $paddingZero;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    .navbar-toggle {
        background-color: $backgroundTransparent;
        padding: $padding10px $padding24px;
        margin: $marginZero;
        border: $border0;
        cursor: pointer;
        display: $d-block;
        position: $positionRelative;
        height: 64px;
        width: $width100Per;
        text-align: left;
    }
    
    .btn-primary.focus, .btn-primary:focus, .btn-primary:active {
        box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 0%)!important;
        background-color: transparent!important;
        border-color: transparent!important;
    }
    .plansBtn.btn-primary:active, .plansBtn.btn-primary:focus, .plansBtn.btn-primary.focus {
        background: #4D7CFE !important;
        border-color: #4D7CFE !important;
    }
    .sidebar-nav {
        position: $positionAbsolute;
        top: 60px;
        width: 242px;
        margin: $marginZero;
        padding: $paddingZero;
        list-style: none;
        margin-top: 2px;
        transition: 0.5s all ease;
        li {
            text-indent: 0px;
         //   height: 64px;
            
            align-items: $textAlignCenter;
            display: $d-flex;
            transition: 0.5s all ease;
            a {
                display: $d-flex;
                text-decoration: none;
                color: $ColorgreyGreyCheckbox;
                font-weight: $fontWeight400;
                font-size: $fontSize14;
                line-height: $lineHeight17;
                letter-spacing: 0.3px;
                height: 64px;
                width: $width100Per;
                align-items: center;
                text-transform: uppercase;
                padding: $paddingZero $padding24px;
                .icon-inner {
                    width: 25px;
                    height: 20px;
                    margin-right: $margin20px;
                    position: $positionRelative;
                    display: inline-block;
                    align-items: $textAlignCenter;
                    background-image: url('../../../../assets/images/sidebaricons.png');
                    transition: 0.5s all ease;
                    img {
                        width: $width100Per;
                        height: $heightAuto;
                    }
                }
                .dashboardIcon {
                    background-position: -3px -31px;
                }  
                .projectIcon {
                    background-position: -2px -66px;
                }
                .taskIcon {
                    background-position: -2px -102px;
                }
                .filesIcon {
                    background-position: -2px -136px;
                }
                .calendericon {
                    background-position: -2px -168px;
                }
                .teamsIcon {
                    background-position: 0px -202px;
                }
                .wallIcon {
                    background-position: 0px -233px;
                }
                .reminderIcon {
                    background-position: 0px -166px;
                }
                .cahtIcon {
                    background-position: 1px -266px;
                }
                .mailIcon {
                    background-position: 1px -302px;
                }
                .notifyIcon {
                    background-position: 1px -337px;
                }
                #basic-button2 {
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    text-decoration: none;
                    color: $ColorgreyGreyCheckbox;
                    font-weight: $fontWeight400;
                    font-size: $fontSize14;
                    line-height: $lineHeight17;
                    letter-spacing: 0.3px;
                    text-transform: uppercase;   
                }                                 
             }
            a:hover {
                text-decoration: none;
                color: $ColorgreyGreyCheckbox;
            }
            a:focus, a:active {
                text-decoration: none;
            }
        }
        li.manage {
            a {
            padding: 0;
            div#manageMenu {
            display: flex;
            padding: 0;
            width: 100%;
            height: 100%;
            align-items: center;
            button.btn.btn-primary {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 24px !important;
            }
            .dropdown-menu {
            a.dropdown-item {
            padding: 0.25rem 1.5rem;
            }
            }
            }
            }
            }
        li.active, li:hover {
            background-color: $BtnPrimaryColorBlue;
            a {
                display: $d-flex;
                text-decoration: none;
                color: $Colorwhite;
                transition: all 0.1s;

                .dashboardIcon {
                    background-position: -39px -31px;
                }
                .projectIcon {
                    background-position: -39px -66px;
                }
                .taskIcon {
                    background-position: -39px -102px;
                }
                .filesIcon {
                    background-position: -39px -136px;
                }
                .calendericon {
                    background-position: -39px -168px;
                }
                .teamsIcon {
                    background-position: -39px -202px;
                }
                .wallIcon {
                    background-position: -39px -233px;
                }
                .reminderIcon {
                    background-position: -42px -166px;
                }
                .cahtIcon {
                    background-position: -40px -266px;
                }
                .mailIcon {
                    background-position: -39px -302px;
                }
                .notifyIcon {
                    background-position: -39px -337px;
                }
                #basic-button2 {
                    color: $Colorwhite;
                }
                #manageMenu {
                    button{
                        color: $Colorwhite;
                    }    
                }
            }
        }
        button.plansBtn.btn.btn-primary {
            background: #4D7CFE;
            border-radius: 4px;
            border-color: #4D7CFE;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            width: calc(100% - 25px);
            text-align: center;
            padding: 10px;
            margin-top: 10px;
            &:hover {
                background: #4D7CFE;
                border-radius: 4px;
                border-color: #4D7CFE;
            }
            img {
                width: $width20;
                margin-right: $margin10px;
            }
        }
     }
 }
 .SidebarWrapper.toggle {
    .sidebar-nav {
        .planBtnWrap  {
            .plansBtn.btn.btn-primary {
                font-size: 12px;
                padding: 5px;
                margin: 5px 0;
                text-align: center;
                span {
                    display: none;
                }
                img {
                    margin: auto;
                }
            }
        }
    }
 }
 #manageMenu button {
    background-color: transparent;
    padding: 0;
    border: 0px;
    display: flex;
    text-decoration: none;
    color: #778CA2;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    appearance: none!important;
    &:after {
        display: none;
    }
    
    
}
#manageMenu .dropdown-menu.show {
    display: block;
    left: 112px!important;
    z-index: 10000!important;
    position: fixed !important;
    .dropdown-item {
        color: $ColorgreyGreyCheckbox;
        font-weight: $fontWeight400;
        font-size: $fontSize14;
        line-height: $lineHeight17;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        height: 42px;
        display: flex;
        align-items: center;
        &:hover {
            background-color: #4D7CFE;
            color: $Colorwhite;
        }
    }
}
.sidebar-nav li:hover #manageMenu button {
    color: $Colorwhite;
}
 .SidebarWrapper.toggle {
    width: 76px;
    .navbar-toggle {
        background-color: transparent;
        padding: 10px 30px;
        margin: 0px;
        border: 0px;
        cursor: pointer;
        display: block;
        position: relative;
        height: 64px;
        width: 100%;
        text-align: left;
    }
    .sidebar-nav {
        position: absolute;
        top: 60px;
        width: 76px;
        margin: 0px;
        padding: 0px;
        list-style: none;
        margin-top: 2px;
        .toggleHide {
            display: $d-none;
        }
        li {
            padding: $paddingZero;
            .icon-inner {
                margin-right: $marginZero;
            }
        }

    }
}
.ContentWrap.contentToggle {
    margin-left: 76px;
}
#basic-menu2 .MuiMenu-paper {
    left: 240px !important;
    z-index: -1;
    .css-6hp17o-MuiList-root-MuiMenu-list {
        filter: drop-shadow(6px 6px 14px rgba(0, 0, 0, 0.08));
        width: 190px;
        height: 185px;
        border: 1px solid #D7DADF;
        border-radius: 4px;
        padding: 0;
        li {
            height: 46px;
            border-bottom: 1px solid #D7DADF;
            align-items: center;
            padding: 0px 22px;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 12px;
            color: #252631;
            &:hover {
                background-color: #4D7CFE;
                color: $Colorwhite;
            }
        }
    }
}

@media screen and (max-width: 85.375em),
screen and (max-height: 48px) { 
    .SidebarWrapper .sidebar-nav li {
        text-indent: 0px;
        height: 50px;
        padding: 0px 24px;
        align-items: center;
        display: flex;
    }
 }

 @media (min-width: 768px) {
    .SidebarWrapper {
       width: 242px;
       border-right: 1px solid $ColorBorderGrey;
       overflow-x: hidden;
    }
 }